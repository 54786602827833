import * as Sentry from '@sentry/tanstackstart-react';

if (!globalThis.Cypress) {
	Sentry.init({
		dsn: 'https://d1b7bb47d5f20f5f78828486bddf7ecc@o42701.ingest.us.sentry.io/4509056762445824',
		release: import.meta.env.VITE_RELEASE || 'local',
		integrations: [
			'replayIntegration' in Sentry && Sentry.replayIntegration(),
			'redisIntegration' in Sentry && Sentry.redisIntegration(),
		].filter(Boolean),
		replaysSessionSampleRate: 0.05,
		replaysOnErrorSampleRate: 1.0,
	});
}
