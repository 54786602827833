import {
	blue,
	green,
	indigo,
	lightGreen,
	orange,
	purple,
	red,
	yellow,
} from '@mui/material/colors';
import { createTheme, css, darken, lighten } from '@mui/material/styles';
import type {} from '@mui/material/themeCssVarsAugmentation';

declare module '@mui/system' {
	interface Shape {
		headerSpacing: string;
	}
}

declare module '@mui/material/styles' {
	interface Mixins {
		additional: ReturnType<typeof css>;
		cancelled: ReturnType<typeof css>;
		delayed: ReturnType<typeof css>;
		changed: ReturnType<typeof css>;
		early: ReturnType<typeof css>;
		singleLineText: ReturnType<typeof css>;
	}
	interface CommonColors {
		pride: string;
		red: string;
		redContrast: string;
		green: string;
		greenContrast: string;
		yellow: string;
		yellowContrast: string;
		orange: string;
		orangeContrast: string;
		blue: string;
		blueContrast: string;
		transparentBackground: string;
		shadedBackground: string;
		doubleShadedBackground: string;
	}
}

// unit: em
const headerSpacing = 3.5;

const pride =
	'linear-gradient(180deg, #FE0000 16.66%,#FD8C00 16.66%, 33.32%,#FFE500 33.32%, 49.98%,#119F0B 49.98%, 66.64%,#0644B3 66.64%, 83.3%,#C22EDC 83.3%);';

const darkDefaultBackground = '#303030';
const darkShadedBackground = lighten(darkDefaultBackground, 0.2);
const darkDoubleShadedBackground = lighten(darkShadedBackground, 0.2);

const lightDefaultBackground = '#fafafa';
const lightShadedBackground = darken(lightDefaultBackground, 0.3);
const lightDoubleShadedBackground = darken(lightShadedBackground, 0.3);

// dummy Theme creation to get this function
// TODO: native css color-contrast() once its shipped
const getContrastText = createTheme().palette.getContrastText;

const createColorWithContrast = <T extends string>(
	colorName: T,
	color: string,
) => ({
	[colorName]: color,
	[`${colorName}Contrast`]: getContrastText(color),
});

export const theme = createTheme({
	cssVariables: {
		colorSchemeSelector: 'class',
	},
	mixins: {
		additional: css`
		color: var(--mui-palette-common-green) !important;
		`,
		early: css`
			color: var(--mui-palette-common-green);
		`,
		cancelled: css`
			text-decoration: line-through;
			text-decoration-color: var(--mui-palette-text-primary);
		`,
		delayed: css`
			color: var(--mui-palette-common-red);
		`,
		changed: css`
			color: var(--mui-palette-common-red)!important;
		`,
		singleLineText: css`
			overflow: hidden;
			max-width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
		`,
	},
	colorSchemes: {
		dark: {
			palette: {
				background: {
					default: darkDefaultBackground,
				},
				common: {
					pride,
					...createColorWithContrast('red', red.A400),
					...createColorWithContrast('green', lightGreen[400]),
					...createColorWithContrast('yellow', yellow[400]),
					...createColorWithContrast('orange', orange[400]),
					...createColorWithContrast('blue', blue[400]),
					shadedBackground: darkShadedBackground,
					doubleShadedBackground: darkDoubleShadedBackground,
					transparentBackground: 'rgba(0, 0, 0, 0.55)',
				},
				mode: 'dark',
				primary: {
					main: blue[700],
				},
				secondary: {
					main: purple.A400,
				},
			},
		},
		light: {
			palette: {
				background: {
					default: lightDefaultBackground,
				},
				common: {
					pride,
					...createColorWithContrast('red', red[700]),
					...createColorWithContrast('green', green[800]),
					...createColorWithContrast('yellow', yellow[600]),
					...createColorWithContrast('orange', orange[400]),
					...createColorWithContrast('blue', indigo[800]),
					shadedBackground: lightShadedBackground,
					doubleShadedBackground: lightDoubleShadedBackground,
					transparentBackground: 'rgba(255, 255, 255, 0.55)',
				},
				mode: 'light',
				primary: {
					main: blue[400],
				},
				secondary: {
					main: purple[400],
				},
			},
		},
	},
	shape: {
		headerSpacing: `${headerSpacing}em`,
	},
	components: {
		MuiDialog: {
			styleOverrides: {
				container: {
					alignItems: 'flex-start',
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: 'standard',
			},
		},
		MuiToolbar: {
			styleOverrides: {
				regular: {
					height: `${headerSpacing}em!important`,
					minHeight: `${headerSpacing}em!important`,
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundImage: 'unset',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				elevation1: {
					backgroundColor: 'inherit',
					backgroundImage: 'unset',
					boxShadow: '0 1px 0 rgba(0, 0, 0, 0.24)',
				},
			},
		},
	},
});
